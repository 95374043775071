import { Component, OnInit } from '@angular/core';
import { SellerDetailsService } from '../../../shared/httpservice/sellerdetails.service';
import {PlatformLocation } from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  // public lat: number = 23.264540;
  // public lng: number = 88.438097;
  public lat: any;
  public lng: any;
  public zoom: number = 12;

  public mobile: any;
  public email: any;
  public address: any;
  public image: any;
  public schedule:any;
  public siteUrl:any;

  constructor(private sellerdetails: SellerDetailsService, public platformLocation: PlatformLocation) { 
    this.siteUrl = (this.platformLocation as any).location.origin;
  }

  ngOnInit() {
    this.sellerdetails.getStore(this.siteUrl).subscribe(data =>{
      this.sellerdetails.getProfile(data[0].sellerId).subscribe(data => {
        if (data.mobile) {
          this.mobile = data.mobile;
        }
        if (data.email) {
          this.email = data.email;
        }
        if (data.address) {
          this.address = data.address;
        }
        if(data.image){
          this.image = data.image;
        }
        if(data.schedule){
          this.schedule = data.schedule;
        }
      });
    })
    this.sellerdetails.getStore(this.siteUrl).subscribe(data =>{
      this.sellerdetails.getMap(data[0].sellerId).subscribe(data => {
        if (data.latitude) {
          this.lat = parseFloat(data.latitude);
        }
        if (data.longitude) {
          this.lng =parseFloat(data.longitude);
        }
        console.log('latitude longitude', this.lat, this.lng);
      });
    })


  }

  subscribe() { }

}