import { Injectable } from '@angular/core';

import { AngularFireDatabase } from 'angularfire2/database';
import { Observable, Subscription, BehaviorSubject, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
//import { switchMap } 'rxjs/operators';
// import { user } from '../../../environments/userdetails';
@Injectable()
export class OrderService {

  constructor(private db: AngularFireDatabase) { 
  }

  getAll(customerId, sellerId) {
    return this.db.list('/storeFrontOrders/' + sellerId + '/' ,{
      query: {
            orderByChild: 'shipping/cust_Id',
            equalTo: customerId
          }
    });
  }
}
