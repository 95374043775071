import { Injectable } from '@angular/core';

import { AngularFireDatabase } from 'angularfire2/database';
import { Observable, Subscription, BehaviorSubject, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
//import { switchMap } 'rxjs/operators';
import { user } from '../../../environments/userdetails';
@Injectable()
export class ApprovedSellerService {

  constructor(private db: AngularFireDatabase) { 
  }
  getAll() {
    return this.db.list('/approved-seller-list/');
  }

  getSeller(url)
  {
    return this.db.list('/approved-seller-list/', {
      query: {
        orderByChild: 'storeUrl',
        equalTo: url
      }
    });
  }

}
