import { Injectable } from '@angular/core';

import { AngularFireDatabase } from 'angularfire2/database';
import { Observable, Subscription, BehaviorSubject, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
//import { switchMap } 'rxjs/operators';
// import { user } from '../../../environments/userdetails';

@Injectable()
export class CategoryService {
  constructor(private db: AngularFireDatabase) {

  }

  // getAll() {
  //   return this.db.list('/categories', {
  //     query: {
  //       orderByChild: 'name'
  //     }
  //   });
  // }

  // getAll() {
  //   return this.db.list('/categories', {
  //     query: {
  //       orderByChild: 'sellerId',
  //       equalTo: user.userId
  //     }
  //   });
  // }

  // getAllhasCategory() {
  //   return this.db.list('/categories', {
  //     query: {
  //       orderByChild: 'hasSubCategory',
  //       equalTo: true
  //     }
  //   });
  // }

  // get(categoryId) {
  //   return this.db.object('/categories/' + categoryId);
  // }

  getAll(sellerId) {
    return this.db.list('/categories/'+sellerId);
  }

  getAllhasCategory(sellerId) {
    return this.db.list('/categories/'+sellerId, {
      query: {
        orderByChild: 'hasSubCategory',
        equalTo: true
      }
    });
  }

  get(categoryId, sellerId) {
    return this.db.object('/categories/' + sellerId + '/' + categoryId);
  }
}
