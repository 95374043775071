import { Injectable } from '@angular/core';

import { AngularFireDatabase } from 'angularfire2/database';
import { Observable, Subscription, BehaviorSubject, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
//import { switchMap } 'rxjs/operators';
// import { user } from '../../../environments/userdetails';
@Injectable()
export class ProductService {

  constructor(private db: AngularFireDatabase) { 
  }

  // getAll() {
  //   return this.db.list('/products', {
  //     query: {
  //       orderByChild: 'title'
  //     }
  //   });

  // }

  // getAll() {
  //   return this.db.list('/products', {
  //     query: {
  //       orderByChild: 'sellerId',
  //       equalTo: user.userId
  //     }
  //   });

  // }

  // getClassifiedProducts(type)
  // {
  //   return this.db.list('/products', {
  //     query: {
  //       orderByChild: 'sellcategoryid',
  //       equalTo: type
  //     }
  //   });
  // }

  // getProductsByCategory(catId) {
  //   return this.db.list('/products', {
  //     query: {
  //       orderByChild: 'categoryId',
  //       equalTo: catId,
  //     }
  //   });
  // }

  // get(productId) {
  //   return this.db.object('/products/' + productId);
  // }

  // search(queryString){
  //     console.log("searching "+queryString)
  //   return this.db.list('/products', {
  //     query: {
  //         orderByChild:'name',
  //         startAt:queryString,
  //         endAt:"\uf8ff"+queryString
  //     }
  //   });
  // }

  getAll(sellerId) {
    return this.db.list('/products/' + sellerId);
  }

  getClassifiedProducts(type, sellerId)
  {
    return this.db.list('/products/' + sellerId, {
      query: {
        orderByChild: 'sellcategoryid',
        equalTo: type
      }
    });
  }

  getProductsByCategory(catId, sellerId) {
    return this.db.list('/products/' + sellerId, {
      query: {
        orderByChild: 'categoryId',
        equalTo: catId,
      }
    });
  }

  get(productId, sellerId) {
    return this.db.object('/products/' + sellerId + '/' + productId);
  }

  search(queryString){
      console.log("searching "+queryString)
    return this.db.list('/products', {
      query: {
          orderByChild:'name',
          startAt:queryString,
          endAt:"\uf8ff"+queryString
      }
    });
  }
}
