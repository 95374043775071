import { Component, OnInit } from '@angular/core';
import { Data, AppService } from '../../../app.service';
import { Router } from "@angular/router";
import { AngularFireAuth } from 'angularfire2/auth';
import { Observable } from 'rxjs/Observable';
import * as firebase from 'firebase';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html'
})
export class TopMenuComponent implements OnInit {
  public currencies = ['INR', 'USD'];
  public currency:any;
  public flags = [
    { name:'English', image: 'assets/images/flags/india.png' },
    { name:'English', image: 'assets/images/flags/gb.svg' },
    { name:'German', image: 'assets/images/flags/de.svg' },
    { name:'French', image: 'assets/images/flags/fr.svg' },
    { name:'Russian', image: 'assets/images/flags/ru.svg' },
    { name:'Turkish', image: 'assets/images/flags/tr.svg' }
  ]
  public flag:any;
  public user: Observable<firebase.User>;
  public userDetails: firebase.User = null;
  userName: any;
  constructor(public appService:AppService, private _firebaseAuth: AngularFireAuth, private router: Router,) {
    this.user = _firebaseAuth.authState;
    this.userGet();
   }

  ngOnInit() {
    this.currency = this.currencies[0];
    this.flag = this.flags[0];    
  }

  public changeCurrency(currency){
    this.currency = currency;
  }

  userGet(){
    this.user.subscribe(
      (user) => {
        if (user) {
          this.userDetails = user;
          this.userName = user.displayName;
        }
        else {
          this.userDetails = null;
          this.router.navigate(['/'])
        }
      }
    );
  }

  public changeLang(flag){
    this.flag = flag;
  }

  logout() {
    this._firebaseAuth.auth.signOut()
    .then((res) => {
    this.router.navigate(['/sign-in']); 
    this.userGet();
    });

  }

}
