import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { user } from '../../../environments/userdetails';

@Injectable()
export class SlidesService {
  constructor(private db: AngularFireDatabase) { }
  // getAll() {
  //   return this.db.list('/slides', {
  //     query: {
  //       orderByChild: 'title'
  //     }
  //   });      
  // }


  // getAll() {
  //   return this.db.list('/slides', {
  //     query: {
  //       orderByChild: 'sellerId',
  //       equalTo: user.userId
  //     }
  //   });
  // }

  getAll(sellerId) {
    return this.db.list('/slides/'+sellerId);
  }
}
