import { Component, OnInit} from '@angular/core';
// import { Menu } from '../../../app.models';
import { Menu } from '../../../app.models';
import { MenuService } from '../../../shared/httpservice/menu.service';
import { CategoryService } from '../../../shared/httpservice/category.service';
import { user } from '../../../../environments/userdetails';

import { SellerDetailsService } from '../../../shared/httpservice/sellerdetails.service';
import {PlatformLocation } from '@angular/common';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  // public menus:Menu[];
  // public menu:Menu;

  public menus:any;
  public menu:any;
  // public userId: any;
  public siteUrl:any;

  constructor(public menuService: MenuService, public categoryService: CategoryService,  public sellerdetails:SellerDetailsService,
    public platformLocation: PlatformLocation) { 
    // this.userId=user.userId;
    this.siteUrl = (this.platformLocation as any).location.origin;
  }

  ngOnInit() { 
    this.sellerdetails.getStore(this.siteUrl).subscribe(data =>{
      this.categoryService.getAll(data[0].sellerId).subscribe(data=>{
        this.menus = data;
        this.menu = data[0];
        // console.log(data);
      });
    })

  }

  openMegaMenu(){
    let pane = document.getElementsByClassName('cdk-overlay-pane');
    [].forEach.call(pane, function (el) {
        if(el.children.length > 0){
          if(el.children[0].classList.contains('mega-menu')){
            el.classList.add('mega-menu-pane');
          }
        }        
    });
  }

}
