import { Component, ChangeDetectionStrategy } from '@angular/core';
import {PlatformLocation } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { Settings, AppSettings } from './app.settings';
import { SellerDetailsService } from './shared/httpservice/sellerdetails.service';
import { ApprovedSellerService } from './shared/httpservice/approved-seller.service';
'use strict';
var approvedSellerId;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  loading: boolean = false;
  public settings:Settings;
  public userId:any;
  public siteUrl:any;
  constructor(public appSettings:AppSettings, public router: Router, public sellerdetails:SellerDetailsService, public platformLocation: PlatformLocation){
    this.settings = this.appSettings.settings;

    console.log(window.location.origin);
  }
  

  ngOnInit() {
   // this.router.navigate(['']);  //redirect other pages to homepage on browser refresh   
   console.log('location.href', (this.platformLocation as any).location.href);
   console.log('location.origin', (this.platformLocation as any).location.origin);
   this.siteUrl = (this.platformLocation as any).location.origin;
  //  this.sellerdetails.getProfile().subscribe(data=>{
  //     if(data.storecolor){
  //       this.settings.theme = data.storecolor;
  //     }
  //     else{
  //       this.settings = this.appSettings.settings;
  //     }

  //     if(data.storename){
  //       this.settings.name = data.storename;
  //     }
  //   }); 

    this.sellerdetails.getStore(this.siteUrl).subscribe(data =>{
      this.sellerdetails.getStoreProfile(data[0].sellerId).subscribe(data=>{
        if(data.storecolor){
          this.settings.theme = data.storecolor;
        }
        else{
          this.settings = this.appSettings.settings;
        }
  
        if(data.storename){
          this.settings.name = data.storename;
        }
      });
    });
    console.log('this.settings123', this.settings);
    }




  ngAfterViewInit(){
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
          window.scrollTo(0,0);
      }
    })  
  }
}
