import { Injectable } from '@angular/core';

import { AngularFireDatabase } from 'angularfire2/database';
import { Observable, Subscription, BehaviorSubject, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
//import { switchMap } 'rxjs/operators';
// import { user } from '../../../environments/userdetails';

@Injectable()
export class BannerService {
  
  constructor(private db: AngularFireDatabase) { }

  // getAll() {
  //   return this.db.list('/banners', {
  //     query: {
  //       orderByChild: 'title'
  //     }
  //   });
      
  // }

  // getAll() {
  //   return this.db.list('/banners', {
  //     query: {
  //       orderByChild: 'sellerId',
  //       equalTo: user.userId
  //     }
  //   });
  // }

  // get(bannerId) { 
  //   return this.db.object('/banners/' + bannerId);
  // }

  getAll(sellerId) {
    return this.db.list('/banners/'+ sellerId);
  }

  get(bannerId, sellerId) { 
    return this.db.object('/banners/' + sellerId + '/' + bannerId);
  }
}
