import { Injectable } from '@angular/core';

import { AngularFireDatabase } from 'angularfire2/database';
import { Observable, Subscription, BehaviorSubject, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
//import { switchMap } 'rxjs/operators';
// import { user } from '../../../environments/userdetails';
@Injectable()
export class UserDetailsService {

  constructor(private db: AngularFireDatabase) { 
  }
  get(userId) { 
    return this.db.object('/storeUsersDetails/billingAddress/' + userId);
  }

   save(data, userId) {
    this.db.object('/storeUsersDetails/billingAddress/' + userId).update(data);
  }

  getAccount(userId) { 
    return this.db.object('/storeUsersDetails/accountInfo/' + userId);
  }
  saveAccount(data, userId) {
    this.db.object('/storeUsersDetails/accountInfo/' + userId).update(data);
  }
}
