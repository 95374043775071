import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { user } from '../../../environments/userdetails';
@Injectable()

export class MenuService {

  constructor(private db: AngularFireDatabase) { 
  }

  // getAll() {
  //   return this.db.list('/menus', {
  //     query: {
  //       orderByChild: 'title'
  //     }
  //   });

  // }

  // getAll() {
  //   return this.db.list('/menus', {
  //     query: {
  //       orderByChild: 'sellerId',
  //       equalTo: user.userId
  //     }
  //   });

  // }

  // get(productId) {
  //   return this.db.object('/menus/' + productId);
  // }

}
