export const environment = {
  production: true,
  firebase: {
    //dev
    
    // apiKey: "AIzaSyDXJ-ZDZnaTbLNcunHYxyB9rD8Bb6wj0Jk",
    // authDomain: "bengalhandloombazar.firebaseapp.com",
    // databaseURL: "https://bengalhandloombazar.firebaseio.com",
    // projectId: "bengalhandloombazar",
    // storageBucket: "bengalhandloombazar.appspot.com",
    // messagingSenderId: "307201988359" 

    //Production
    apiKey: "AIzaSyCROSeueEdecN4OtCNaKLEIRH-hTA2q-44",
    authDomain: "bengalhandloombazar-479a3.firebaseapp.com",
    databaseURL: "https://bengalhandloombazar-479a3.firebaseio.com",
    projectId: "bengalhandloombazar-479a3",
    storageBucket: "bengalhandloombazar-479a3.appspot.com",
    messagingSenderId: "797511382434",


  }
};
