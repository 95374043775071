import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders , HttpParams} from '@angular/common/http';

import { AngularFireDatabase } from 'angularfire2/database';
import { Observable, Subscription, BehaviorSubject, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
// import { user } from '../../../environments/userdetails';

//import { switchMap } 'rxjs/operators';

@Injectable()
export class apiService {
  // private ROOT_URL = 'http://localhost:3333';
  private ROOT_URL = 'https://api.bhbazar.com/api';
  constructor(private http: HttpClient) { }

 async sendEmail(emailData,customerquery,sellerId){
   console.log('emailData', emailData);
   var headers = new HttpHeaders();
   let body = new HttpParams();
   headers.append('Content-Type', 'application/x-www-form-urlencoded');
   body = body.set('senderemail', emailData.email);
   body = body.set('senderName', emailData.name);
   body = body.set('senderPhone', emailData.phone);
   body = body.set('message', emailData.message);
   body = body.set('type', customerquery);
   body = body.set('sellerId', sellerId);
   this.http.post(this.ROOT_URL+'/sendEmail',  body, {headers: headers}).subscribe((result:any)=>{
     console.log('resut', result);
   })
      //  return this.http.get(`${this.ROOT_URL}/sendEmail/${user.userId}?senderemail=${emailData.email}&senderName=${emailData.name}&senderPhone=${emailData.phone}&message=${emailData.message}&type=${customerquery}`).toPromise();
 }

  // async generatePayuHash(hashData){
  //   return this.http.get(`${this.ROOT_URL}/getHash/${hashData}`).toPromise();
  // }

  async initiatePayment(paymentData){
    console.log('paymentData' , paymentData);
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    let body = new HttpParams();
    body = body.set('trxNo', paymentData.trxNo);
    body = body.set('amount', paymentData.amount);
    body = body.set('email', paymentData.personalData.email);
    body = body.set('phone', paymentData.personalData.phone);
    body = body.set('lastname', paymentData.personalData.lastName);
    body = body.set('firstname', paymentData.personalData.firstName);
    body = body.set('udf1', paymentData.sellerId);

    console.log('Body Going to Serve', body);
    return this.http.post(this.ROOT_URL+'/makePayment',  body, {headers: headers}).toPromise();
    // return this.http.get(`${this.ROOT_URL}/makePayment/${paymentData.trxNo}?txnid=${paymentData.trxNo}&amount=${paymentData.amount}&email=${paymentData.personalData.email}&phone=${paymentData.personalData.phone}&lastname=${paymentData.personalData.lastName}&firstname=${paymentData.personalData.firstName}`).subscribe(data=>{
    //   console.log('data', data);
    // });
  }
}

