import { Injectable } from '@angular/core';

import { AngularFireDatabase } from 'angularfire2/database';
import { Observable, Subscription, BehaviorSubject, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
//import { switchMap } 'rxjs/operators';
// import { user } from '../../../environments/userdetails';
@Injectable()
export class SellerDetailsService {

  constructor(private db: AngularFireDatabase) { 
  }
  getProfile(sellerId) { 
    return this.db.object('/seller-details/profiles/' + sellerId);
  }

  getStoreProfile(sellerId) { 
    return this.db.object('/seller-details/profiles/' + sellerId);
  }

  getMap(sellerId) { 
    return this.db.object('/map/' + sellerId);
  }

  getKeywords(sellerId) { 
    return this.db.object('/keywords/' + sellerId);
  }

  getStore(url){

    return this.db.list('/approved-seller-list/', {
      query: {
        orderByChild: 'storeUrl',
        equalTo: url
      }
    });
  }  

}
