import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { Settings, AppSettings } from '../app.settings';
import { AppService } from '../app.service';
import { Category } from '../app.models';
import { SidenavMenuService } from '../theme/components/sidenav-menu/sidenav-menu.service';
import { SidenavMenu } from '../theme/components/sidenav-menu/sidenav-menu.model';

import { CategoryService } from '../shared/httpservice/category.service';
import { SellerDetailsService } from '../shared/httpservice/sellerdetails.service';
import {PlatformLocation } from '@angular/common';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  providers: [ SidenavMenuService ]
})
export class PagesComponent implements OnInit {
  public showBackToTop:boolean = false;
  public categories: any;
  public category:Category;
  // public sidenavMenuItems:Array<any>;
  public sidenavMenuItems =[];
  public searchQuery : string;
  public sideMenu:SidenavMenu;
  public storeName: any;
  public mobile: any;
  @ViewChild('sidenav') sidenav:any;
  public siteUrl:any;

  public settings: Settings;
  constructor(public appSettings:AppSettings,
              public appService:AppService,
              public catService: CategoryService,
              public sidenavMenuService:SidenavMenuService,
              public router:Router,
            public sellerdetails:SellerDetailsService,
            public platformLocation: PlatformLocation) {
    this.settings = this.appSettings.settings;
    this.siteUrl = (this.platformLocation as any).location.origin;
  }

  ngOnInit() {
    this.sellerdetails.getStore(this.siteUrl).subscribe(data =>{
      this.sellerdetails.getProfile(data[0].sellerId).subscribe(data=>{
        if(data.storename){
          this.storeName = data.storename;
        }
        if(data.mobile){
          this.mobile = data.mobile;
        }
      });
    })
    // this.getCategories();
    // // this.sidenavMenuItems = this.sidenavMenuService.getSidenavMenuItems();
    // console.log('sidenavMenuItems', this.sidenavMenuItems);

    // this.sidenavMenuItems =[new SidenavMenu (1, 'Soap', '/products/-LJjjXclE6U_GG6QURnX', null, null, false, 0),
    //                         new SidenavMenu (2, 'Oil', '/products', null, null, true, 0),
    //                         new SidenavMenu (3, 'Hair Oil', '/products', null, null, true, 2),
    //                         new SidenavMenu (4, 'Body Oil', '/products', null, null, true, 3)]    

    let tempData = [];
    this.sellerdetails.getStore(this.siteUrl).subscribe(data =>{
      this.catService.getAll(data[0].sellerId).subscribe(data=>{
        this.categories = data;
        // console.log('this.categories', this.categories);
        let counter = 1;
        let hasSubmenubool = false;
        this.categories.forEach(function(item){
          // console.log('item = ', item);
          if(item.hasSubCategory == true)
            hasSubmenubool = true;
            tempData.push(new SidenavMenu(item.$key, item.name, '/products/'+item.$key, null, null, item.hasSubCategory, item.parentId));
          counter++;
        });
        this.sidenavMenuItems = tempData;
        this.category = data[0];
      });
    })
  }

  // public getCategories(){
  //   this.appService.getCategories().subscribe(data => {
  //     this.categories = data;
  //     this.category = data[0];
  //     this.appService.Data.categories = data;
  //   })
  // }

  public changeCategory(event){
    if(event.target){
      this.category = this.categories.filter(category => category.name == event.target.innerText)[0];
      // console.log('this.category', this.category);
    }
    if(window.innerWidth < 960){
      this.stopClickPropagate(event);
    }
  }

  public remove(product) {
      const index: number = this.appService.Data.cartList.indexOf(product);
      if (index !== -1) {
          this.appService.Data.cartList.splice(index, 1);
          this.appService.Data.totalPrice = this.appService.Data.totalPrice - product.newPrice;
      }
  }

  public clear(){
    this.appService.Data.cartList.length = 0;
  }


  public changeTheme(theme){
    this.settings.theme = theme;
  }

  public stopClickPropagate(event: any){
    event.stopPropagation();
    event.preventDefault();
  }

  public search(){
      if(this.router.routerState.snapshot.url.substring(1,9)!='products'){
          this.router.navigate(['products']);
      }
      this.appService.changeSearchQuery(this.searchQuery);
  }


  public scrollToTop(){
    var scrollDuration = 200;
    var scrollStep = -window.pageYOffset  / (scrollDuration / 20);
    var scrollInterval = setInterval(()=>{
      if(window.pageYOffset != 0){
         window.scrollBy(0, scrollStep);
      }
      else{
        clearInterval(scrollInterval);
      }
    },10);
    if(window.innerWidth <= 768){
      setTimeout(() => { window.scrollTo(0,0) });
    }
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    ($event.target.documentElement.scrollTop > 300) ? this.showBackToTop = true : this.showBackToTop = false;
  }

  ngAfterViewInit(){
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.sidenav.close();
      }
    });
    this.sidenavMenuService.expandActiveSubMenu(this.sidenavMenuService.getSidenavMenuItems());
  }

  public closeSubMenus(){
    if(window.innerWidth < 960){
      this.sidenavMenuService.closeAllSubMenus();
    }
  }

}
